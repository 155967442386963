import React from "react"
import cx from "classnames"
import { Link } from "gatsby"

import { IMG } from "../const"
import Layout from "../components/layout"
import "../assets/styles/commons/lists/_common__numeric-columns__block.scss"
import "../assets/styles/commons/_lists.scss"
import "../assets/styles/commons/common__animated-section.scss"
import * as strategyStyles from "../assets/styles/product-strategy.module.scss"
import AnimatedLayer from "../components/animated-layer"
import TestimonialsSlider from "../components/testimonials-slider"
import FeedbackSection from "../components/feedback-section"

const STEPS = [
  {
    id: 1,
    title: "We discover your product competition",
    text:
      "Every idea has a competition on a market, but all your competitors are a little bit different: they have different strategies, serve different clients’ needs and might be focused on different users.",
    image: "product-strategy1",
    imageTitle: "Product strategy examples research",
  },
  {
    id: 2,
    title: "Together we identify the main problems your product solves",
    text:
      "Only those products that resolve clients’ important problems can gain a rapid success. We have to challenge your product against important problems it may solve.",
    image: "product-strategy2",
    imageTitle: "Product marketing strategy analysis",
  },
  {
    id: 3,
    title: "Together we identify important Users",
    text:
      "Unfortunately, not every user will bring the same value back to your, so you better understand, who is an important user and who is not. Stay focused on the right User Profiles.",
    image: "product-strategy3",
    imageTitle: "Identify product positioning strategy",
  },
  {
    id: 4,
    title: "We create a list of important features",
    text:
      "Not everything that you consider a good idea will be accepted by your users. And not every feature brings the same value to the important ones. Keep your focus on what is important for the user not for you.",
    image: "product-strategy4",
    imageTitle: "Strategical features of the product",
  },
  {
    id: 5,
    title: "Together we update the product roadmap based on our discovery",
    text:
      "Based on our previous knowledge and discovery we update the Product Roadmap, which will serve your important users better.",
    image: "product-strategy5",
    imageTitle: "Strategy for product roadmap",
  },
]

const StrategyPage = () => {
  return (
    <Layout pageTitle="Product Development Strategy & Audit">
      {({ handleOpen, isOpenContactModal }) => {
        return (
          <>
            <main className={strategyStyles.strategy}>
              <article>
                <section className="common__animated-section">
                  <AnimatedLayer>
                    <div className="common__animated-section__heading">
                      <div className="common__animated-section__row common__article__section">
                        <div className="common__animated-section__col">
                          <h1>
                            Product Development:
                            <br /> Strategy & Audit
                          </h1>
                          <h3>
                            Create an efficient product strategy and audit your
                            current product plan
                          </h3>
                          <button
                            className="goldButton darkBackground"
                            onClick={handleOpen}
                            title="Order a product strategy now"
                            aria-expanded={
                              isOpenContactModal ? "true" : "false"
                            }
                            aria-label={
                              isOpenContactModal
                                ? "Hide contact form"
                                : "Show contact form"
                            }
                          >
                            Order a <span>product</span> strategy{" "}
                            <span>now</span>
                          </button>
                        </div>
                        <div className="common__animated-section__static-img">
                          <div className="common__animated-section__img-wrapper">
                            <img
                              src={`${IMG.INDEX}/img/strategy/strategyaudit.svg`}
                              width="171"
                              height="218"
                              alt="Product development strategy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </AnimatedLayer>
                </section>
                <section
                  className={cx(
                    strategyStyles.strategyExpertise,
                    "common__article__section upTo2cols"
                  )}
                >
                  <h2>Product Development Expertise</h2>
                  <div>
                    <dl className={cx(strategyStyles.chips, "common__chips")}>
                      <dt>
                        <strong>300+</strong> Product cases
                      </dt>
                      <dd>
                        <Link to="/portfolio/business_digitalization/">
                          Business Process Optimization
                        </Link>
                      </dd>
                      <dd>
                        <Link to="/portfolio/real_estate/">Real Estate</Link>
                      </dd>
                      <dd>
                        <Link to="/portfolio/marketing_tech/">
                          Marketing Tech
                        </Link>
                      </dd>
                      <dd>
                        <Link to="/portfolio/data_analytics_data_visualization/">
                          Data Management &amp; Analytics
                        </Link>
                      </dd>
                      <dd>
                        <Link to="/portfolio/recommendation_system/">
                          Recommendation Systems
                        </Link>
                      </dd>
                      <dd>
                        <Link to="/portfolio/education_tech/">
                          Education Tech
                        </Link>
                      </dd>
                      <dd>
                        <Link to="/portfolio/hr_recruitment/">HR</Link>
                      </dd>
                      {/* <dd>Telecommunication</dd> */}
                    </dl>
                  </div>
                  <div>
                    <p>
                      For the last 6 years, our team has been building products
                      for companies all over the world. During this period of
                      time, we have gone through and discovered more than 300+
                      product cases, in
                      {` `}
                      <Link to="/portfolio/marketing_tech/">
                        Marketing Tech
                      </Link>
                      ,{` `}
                      <Link to="/portfolio/business_digitalization/">
                        Business Process Optimization
                      </Link>
                      ,{` `}
                      <Link to="/portfolio/real_estate/">Real Estate</Link>,
                      {/* {` `}Telecommunication, */}
                      {` `}
                      <Link to="/portfolio/recommendation_system/">
                        Recommendation Systems
                      </Link>
                      ,{` `}
                      <Link to="/portfolio/education_tech/">
                        Education Tech
                      </Link>
                      ,{` `}
                      <Link to="/portfolio/data_analytics_data_visualization/">
                        Data Management &amp; Analytics
                      </Link>
                      , and
                      {` `}
                      <Link to="/portfolio/hr_recruitment/">HR</Link>.
                    </p>
                    <p>
                      The knowledge and expertise we got through this process,
                      definitely can be valuable to our clients. With this
                      offer, we will explain how everything we know about
                      Product Management can help you.
                    </p>
                  </div>
                </section>
                <section className="common__article__section__doubleIndentBottom common__numeric-columns__block">
                  <h2>How does the Product Strategy building work?</h2>
                  <ol className="common__numeric-columns__block__content">
                    {STEPS.map(item => (
                      <li
                        className="common__numeric-columns__block__item"
                        key={item.id}
                      >
                        <picture className="common__numeric-columns__block__picture">
                          <source
                            srcSet={`${IMG.PRODUCT_STRATEGY}/${item.image}.avif,	${IMG.PRODUCT_STRATEGY}/${item.image}@2x.avif 2x,	${IMG.PRODUCT_STRATEGY}/${item.image}@3x.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            srcSet={`${IMG.PRODUCT_STRATEGY}/${item.image}.webp,	${IMG.PRODUCT_STRATEGY}/${item.image}@2x.webp 2x,	${IMG.PRODUCT_STRATEGY}/${item.image}@3x.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            srcSet={`${IMG.PRODUCT_STRATEGY}/${item.image}.jpg,		${IMG.PRODUCT_STRATEGY}/${item.image}@2x.jpg 2x,	${IMG.PRODUCT_STRATEGY}/${item.image}@3x.jpg 3x`}
                          />
                          <img
                            src={`${IMG.PRODUCT_STRATEGY}/${item.image}@3x.jpg`}
                            alt={item.imageTitle}
                            itemProp="contentUrl url"
                            width="664"
                            height="160"
                            loading="lazy"
                          />
                          <meta itemProp="width" content="1992" />
                          <meta itemProp="height" content="480" />
                        </picture>
                        <div className="common__numeric-columns__block__text">
                          <h3>{item.title}</h3>
                          <p>{item.text}</p>
                        </div>
                      </li>
                    ))}
                  </ol>
                </section>

                <section className="bg__nobleGray">
                  <div className="common__article__section upTo2colsImgTxt">
                    <div>
                      <img
                        src={`${IMG.INDEX}/img/strategy/strategy_1.svg`}
                        width="288"
                        height="192"
                        alt="Product development strategy building"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h2>Building a Strategy</h2>
                      <p>
                        Usually, the whole Strategy Building process may involve
                        a few experts and will take 4-5 weeks in total. It
                        requires some time investment, effort, and it is not
                        free. However, from what we learned, not having a good
                        Product Strategy costs even more.
                      </p>
                      <p className="mt2">
                        <button
                          className="goldButton"
                          onClick={handleOpen}
                          title="Order a product strategy now"
                          aria-expanded={isOpenContactModal ? "true" : "false"}
                          aria-label={
                            isOpenContactModal
                              ? "Hide contact form"
                              : "Show contact form"
                          }
                        >
                          Order a <span>product</span> strategy <span>now</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </section>
                <section className="common__article__section upTo2cols">
                  <section>
                    <h2 className="section-title">Impact</h2>
                    <ul className="list__checkmarked">
                      <li>
                        A better understanding of your market and competition
                      </li>
                      <li>
                        A better understanding of your main clients and their
                        needs
                      </li>
                      <li>Better decisions on your product roadmap</li>
                      <li>Happier users and better product results</li>
                    </ul>
                  </section>
                  <section>
                    <h2 className="section-title">Deliverables</h2>
                    <ul className="list__checkmarked">
                      <li>Competitor research analytics</li>
                      <li>Market problem definition</li>
                      <li>Key product features analytics</li>
                      <li>The user personas and analytics</li>
                      <li>
                        The product roadmap update for the next 6–12 months
                      </li>
                    </ul>
                  </section>
                </section>
                <section className="bg__nobleGray">
                  <div className="common__article__section common__projects-teaser">
                    <h2>Our Successful Projects</h2>
                    <p>
                      Here, you can check out our successful projects! We have
                      described the most compelling solutions and approaches to
                      meet challenges and advantages.
                    </p>
                    <Link to="/projects/" className="seeMore">
                      <figure>
                        <picture className="portfolioChapter__singleProjectSummary__screenshotPlaceholder">
                          <source
                            srcSet={`${IMG.PORTFOLIO}/ageline/382.avif,	${IMG.PORTFOLIO}/ageline/764.avif 2x,	${IMG.PORTFOLIO}/ageline/984.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            srcSet={`${IMG.PORTFOLIO}/ageline/382.webp,	${IMG.PORTFOLIO}/ageline/764.webp 2x,	${IMG.PORTFOLIO}/ageline/984.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            srcSet={`${IMG.PORTFOLIO}/ageline/382.jpg,	${IMG.PORTFOLIO}/ageline/764.jpg 2x,	${IMG.PORTFOLIO}/ageline/984.jpg 3x`}
                          />
                          <img
                            src={`${IMG.PORTFOLIO}/ageline/984.jpg`}
                            alt="Product strategy example"
                            width="288"
                            height="164"
                            loading="lazy"
                          />
                        </picture>
                        <picture className="portfolioChapter__singleProjectSummary__screenshotPlaceholder">
                          <source
                            srcSet={`${IMG.PORTFOLIO}/thespoke/382.avif,	${IMG.PORTFOLIO}/thespoke/764.avif 2x,	${IMG.PORTFOLIO}/thespoke/984.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            srcSet={`${IMG.PORTFOLIO}/thespoke/382.webp,	${IMG.PORTFOLIO}/thespoke/764.webp 2x,	${IMG.PORTFOLIO}/thespoke/984.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            srcSet={`${IMG.PORTFOLIO}/thespoke/382.jpg,	${IMG.PORTFOLIO}/thespoke/764.jpg 2x,		${IMG.PORTFOLIO}/thespoke/984.jpg 3x`}
                          />
                          <img
                            src={`${IMG.PORTFOLIO}/thespoke/984.jpg`}
                            alt="Product strategy examples"
                            width="288"
                            height="164"
                            loading="lazy"
                          />
                        </picture>
                      </figure>
                      <span>Discover projects</span>
                    </Link>
                  </div>
                </section>
                <section className="common__article__section">
                  <h2 className="section-title">Clients’ testimonials</h2>
                  <TestimonialsSlider />
                </section>
              </article>
            </main>
            <FeedbackSection number={0} />
          </>
        )
      }}
    </Layout>
  )
}

export default StrategyPage
