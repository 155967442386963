import React from "react";
import cx from "classnames";
import { useSpring, animated } from 'react-spring';
import { IMG } from "../../const"
import * as styles from "./styles.module.scss";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
const trans2 = (x, y) => `translate3d(${x / 8}px,${y / 8}px,0)`
const trans3 = (x, y) => `translate3d(${x / 6}px,${y / 6}px,0)`
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`

const AnimatedLayer = ({children}) => {
	const [props, set] = useSpring(() => ({ xy: [0, 0], reverse: true, config: { mass: 10, tension: 550, friction: 140 } }))

  return (
    <div className={styles.bgBlack} onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })} role="presentation">
      {children}
      <div id="scene" className={cx(styles.sceneBlock, styles.scene)}>
        <animated.div style={{ transform: props.xy.interpolate(trans4) }}
                      className={cx(styles.layer, styles.order6)} data-depth="0.40">
          <div className={cx(styles.rectangle, styles.rectangle3)}>
            <img
              src={`${IMG.INDEX}/img/strategy/rectangle_03.svg`}
              width="578"
              height="448"
              alt=""
            />
          </div>
        </animated.div>
        <animated.div style={{ transform: props.xy.interpolate(trans3) }}
                      className={cx(styles.layer, styles.order5)} data-depth="0.25">
          <div className={cx(styles.rectangle, styles.rectangle4)}>
            <img
              src={`${IMG.INDEX}/img/strategy/rectangle_04.svg`}
              width="400"
              height="311"
              alt=""
            />
          </div>
        </animated.div>
        <animated.div style={{ transform: props.xy.interpolate(trans2) }}
                      className={cx(styles.layer, styles.order4)} data-depth="0.15">
          <div className={cx(styles.rectangle, styles.rectangle6)}>
            <img
              src={`${IMG.INDEX}/img/strategy/rectangle_06.svg`}
              width="482"
              height="374"
              alt=""
            />
          </div>
        </animated.div>
        <animated.div style={{ transform: props.xy.interpolate(trans3) }}
                      className={cx(styles.layer, styles.order3)} data-depth="0.25">
          <div className={cx(styles.rectangle, styles.rectangle2)}>
            <img
              src={`${IMG.INDEX}/img/strategy/rectangle_02.svg`}
              width="745"
              height="575"
              alt=""
            />
          </div>
        </animated.div>
        <animated.div style={{ transform: props.xy.interpolate(trans2) }}
                      className={cx(styles.layer, styles.order2)} data-depth="0.15">
          <div className={cx(styles.rectangle, styles.rectangle1)}>
            <img
              src={`${IMG.INDEX}/img/strategy/rectangle_01.svg`}
              width="563"
              height="444"
              alt=""
            />
          </div>
        </animated.div>
        <animated.div style={{ transform: props.xy.interpolate(trans1) }}
                      className={cx(styles.layer, styles.order1)} data-depth="0.05">
          <div className={cx(styles.rectangle, styles.rectangle5)}>
            <img
              src={`${IMG.INDEX}/img/strategy/rectangle_05.svg`}
              width="670"
              height="518"
              alt=""
            />
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default AnimatedLayer
